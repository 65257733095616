<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="350px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ $t('message.resetPassword') + ": " + userName }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="passwordResetForn" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs6 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.lastReset') }}</v-flex>
                        <v-flex xs6 lg7 md6 py-1 class="font-weight-bold">{{ lastReset }}</v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg5 md6 pl-5 py-1 class="align-start">{{ $t('message.newPassword') }}</v-flex>
                        <v-flex xs6 lg7 md6 py-1>
<!--                            <v-text-field-->
<!--                                    :rules="[...validationRules.required, ...validationRules.newPassword]"-->
<!--                                    :value="newPassword"-->
<!--                                    autocomplete="new-password"-->
<!--                                    background-color="grey lighten-3"-->
<!--                                    class="force-text-left"-->
<!--                                    clearable-->
<!--                                    dense-->
<!--                                    readonly-->
<!--                                    hide-details="auto"-->
<!--                                    solo-->
<!--                                    @change="newPassword = $event"-->
<!--                            />-->
                            <div class="black--text text--darken-1 pl-1 py-1 grey lighten-2" style="border: solid 1px grey; border-radius: 3px; min-height: 30px">{{ newPassword }}</div>
                            <v-btn small @click="suggestPassword" class="mt-2">{{ $t('message.generatePassword') }}</v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.notifyUser') + '?' }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-checkbox
                                    :label="$t('message.yes')"
                                    :true-value="1"
                                    :value="sendNotification"
                                    class="mt-1"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="sendNotification"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn
                        :loading="loading.reset"
                        color="info"
                        small
                        @click="resetPassword()"
                >{{ $t('message.reset') }}</v-btn>
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {api} from "Api";
import srs from 'secure-random-string'
import {validationRules} from "@/store/modules/appic/constants";
import {mapGetters} from "vuex";
import {isObject} from "Helpers/helpers";

export default {
    name: "UserPasswordReset",
    props: ['userId','userName','dialog','dialogClosed','resetDone'],
    data() {
        return {
            currentPassword: null,
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            lastReset: '',
            loading: {
                get: false,
                reset: false
            },
            newPassword: null,
            // rules: {
            //     characters: v => (v && /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(v)) || this.$t('message.userAppicPasswordRule'),
            //     empty: v => !!v || this.$t('message.required'),
            // },
            sendNotification: 1,
            validForm: true
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        getUserPasswordToResetById() {
            return new Promise((resolve, reject) => {
                api
                    .get('/users/' + this.userId + '/password' )
                    .then(response => {
                        resolve(response.data.data[0])
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        suggestPassword() {
            // this.newPassword = srs({length: 6, alphanumeric: true})
            // this.newPassword = srs({length: 10, alphanumeric: true});
            const generator = require('generate-password-browser');
            this.newPassword = generator.generate({
                length: 10,
                numbers: true,
                symbols: true,
                excludeSimilarCharacters: true
            });
            return true;
        },
        resetPassword() {
            let promise = new Promise((resolve, reject) => {
                api
                    .put("/users/" + this.userId + '/password', {
                        newPassword: this.newPassword,
                        sendNotification: this.sendNotification
                    })
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
            if(this.$refs.passwordResetForn.validate()) {
                this.loading.reset = true
                promise
                    .then(response => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.userPasswordReset'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.reset = false
                            this.$emit('reset-done')
                            this.editDialog = false
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if(Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.userPasswordNotReset'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.userPasswordNotReset'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.reset = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.userPasswordNotReset'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.reset = false
                    })
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                this.loading.get = true
                this.getUserPasswordToResetById()
                    .then((data) => {
                        this.lastReset = data.User.password_reset
                        this.currentPassword = data.User.newpassword
                        this.newPassword = null
                        this.loading.get = false
                    })
                    .catch(() => {
                        this.loading.get = false
                    })
            } else {
                this.lastReset = ''
                this.currentPassword = null
                this.newPassword = null
            }
            this.edit_dialog = value
        }
    }
}
</script>

<style>

</style>